import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "react-i18next";

const QueryComp = () => {
    return (
      <StaticQuery
        query={graphql`
          query {
            companyInfo: allDataJson(filter: { id: { eq: "accueil" } }) {
              edges {
                node {
                  sections {
                    id
                    config {
                      transition
                      images {
                        path
                        alt
                      }
                    }
                    title
                    description
                    items {
                      title
                      text
                      linkText
                      link
                      image
                      alt
                    }
                  }
                }
              }
            }
            images: allFile(
              filter: { relativePath: { glob: "services/*.*" } }
              sort: { fields: relativePath, order: ASC }
            ) {
              edges {
                node {
                  relativePath
                  name
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 75) {
                      ...GatsbyImageSharpFluid
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Services data={data} />}
      />
    );
  };
  

const Services = ({data}) => {
    let mainService = data.companyInfo.edges[0].node.sections.find(
      section => section.id === "services"
    );
    let images = data.images.edges;
    let imageTruck = images.find(img=>img.node.relativePath.search("truck") > -1 ).node.childImageSharp.fluid
    const { t } = useTranslation();

    function getServicesComponents(){
      let comps = mainService.items.map(item => (
        <div key={item.title}  className="col-md-4">
            <div className="feature">
                <div className="feature-thumb"><Image fluid={images.find(img=>img.node.relativePath.search(item.image) > -1 ).node.childImageSharp.fluid} alt={item.alt}/></div>
                <div className="feature-content">
                    <h6>{t(item.title)}</h6>
                    <p>{t(item.text)}</p><a className="feature-link" href={t(item.link)}>{t(item.linkText)}</a>
                </div>
            </div>
        </div>
      ))
      return comps
    }

    return (
        <>
             <section className="module">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 m-auto pb-5">
                      <Image fluid={imageTruck} alt={"problemes de ventilation"}/>
                    </div>
                  </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-9 m-auto text-center">
                            <h1><strong>{t(mainService.title)}</strong></h1>
                            <p className="lead">{t(mainService.description)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="space" style={{marginTop:`60px`}}></div>
                        </div>
                    </div>
                    <div className="row">
                        {getServicesComponents()}
                    </div>
                </div>
            </section>
        </>
        );
    }

    export default QueryComp;
