import React, { useEffect } from "react";
import Image from "gatsby-image";
import { jarallax } from "jarallax";
import { Link, StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

const QueryComp = () => {
    return (
      <StaticQuery
        query={graphql`
          query {
            companyInfo: allDataJson(filter: { id: { eq: "accueil" } }) {
              edges {
                node {
                  sections {
                    id
                    items {
                        title
                        from
                        to
                        sign
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Happy data={data} />}
      />
    );
  };
  
  const Happy = ({ data }) => {
      let mainHappy = data.companyInfo.edges[0].node.sections.find(
          section => section.id === "happy"
      );
      const { t } = useTranslation();

      function getHappyComponents(){
          let comps = mainHappy.items.map(item => (
              <div key={item.title} className="col-md-3">
                <div className="counter">
                    <div className="counter-icon"><span className="icon-energy icons"></span></div>
                    <div className="counter-number">
                        <h6><strong className="counter-timer" data-from={t(item.from)} data-to={t(item.to)}>0</strong>{t(item.sign)}</h6>
                    </div>
                    <div className="counter-title">{t(item.title)}</div>
                </div>
             </div>
          ))
          return comps;
      }
      return (
        <section className="module bg-gray p-b-0">
            <div className="container">
                <div className="row">
                   {getHappyComponents()}
                </div>
            </div>
        </section>
      );
    };
export default QueryComp;