import React from 'react';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero'
import Service from '../components/services/services'
import Happy from '../components/happyCustomer/happy'
import withI18next from "../components/utilities/withI18next";
import SEO from "../components/seo/seo"

const Page = props => {
  return (
    <>
      <SEO title={props.t("seoCompanyName")} desc={props.t("seoCompanyDescription")} />
      <Layout>
          <Hero/>
          <Service/>
          <Happy/>
      </Layout>
    </>
  )
}
export default withI18next({ ns: "common" })(Page);